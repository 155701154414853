<template>
    <div class="commence-status-child-table">
        <div class="by-begion-table">
            <el-table
                :data="tableData"
                border
                show-summary
            >
                <el-table-column
                    prop="date"
                    label="工程状态"
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="工程数量"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="预计方量"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="已用方量"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="订单数"
                >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="发货单数量"
                >
                </el-table-column>
            </el-table>
        </div>
        <div class="by-begion-echarts">
            <div class="by-begion-echarts-two">
                <h1>工程数量</h1>
                <div style="width:100%;height:240px" id="commenceStatusIdOne"></div>
            </div>
            <div class="by-begion-echarts-two">
                <div class="echarts-box-right">
                    <h1>方量对比</h1>
                    <span>单位(方)</span>
                </div>
                <div style="width:100%;height:240px" id="commenceStatusIdTwo"></div>
            </div>
        </div>
    </div>
</template>

<script>
// 开工状态
export default {
    data() {
        return {
            tableData: [
                {
                    date: '2016-05-02',
                    name: '2000',
                    address: '200',
                }, {
                    date: '2016-05-04',
                    name: '2000',
                    address: '200',
                }, {
                    date: '2016-05-01',
                    name: '2000',
                    address: '200',
                }, {
                    date: '2016-05-03',
                    name: '2000',
                    address: '200',
                }, {
                    date: '2016-05-03',
                    name: '2000',
                    address: '200',
                }, {
                    date: '2016-05-03',
                    name: '2000',
                    address: '200',
                },
            ],
            selectTimeData: ['2020-01-01', '2020-06-20'],
        };
    },
    // props: {
    //     selectTimeData: {
    //         type: Array,
    //     },
    // },
    mounted() {
        this.salesanalysisGclx();
    },
    methods: {
        commenceStatusIdOneFun() {
            const myChart = this.$echarts.init(document.getElementById('commenceStatusIdTwo'));
            const option = {
                grid: {
                    top: '13%',
                    left: '3%',
                    right: '8%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['已用方量', '预计方量'],
                    x: 'right',
                    padding: [20, 20, 0, 0],
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    data: ['任城区', '经开区', '高新区'],
                    type: 'category',
                    nameTextStyle: {
                        padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: '已用方量',
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar',
                        barWidth: 15,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'right',
                            fontFamily: 'QuartzLTStd',
                        },
                    },
                    {
                        name: '预计方量',
                        data: [20, 90, 50, 180, 170, 10, 30],
                        type: 'bar',
                        barWidth: 15,
                        color: '#F0AF56',
                        label: {
                            show: true,
                            fontFamily: 'QuartzLTStd',
                            position: 'right',
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        commenceStatusIdTwoFun() {
            const myChart = this.$echarts.init(document.getElementById('commenceStatusIdOne'));
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value;
                    },
                },
                xAxis: {
                    data: ['驯鹿', '火箭', '飞机'],
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                },
                yAxis: {
                    axisLabel: {
                        color: '#fff',
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#fff',
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: 'hill',
                        type: 'pictorialBar',
                        // 位置偏移
                        barCategoryGap: '50%',
                        // 图形宽度
                        barWidth: 50,
                        // 图形形状
                        symbol: 'path://M150 50 L130 130 L170 130  Z',
                        itemStyle: {
                            normal: {
                                opacity: 0.9,
                                color: function (params) {
                                    const colorList = [
                                        '#E44F4F', '#3F7ECA', '#4CA295',
                                    ];
                                    return colorList[params.dataIndex];
                                },
                            },
                            emphasis: {
                                opacity: 1,
                            },
                        },
                        data: [123, 60, 25],
                        z: 10,
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            fontSize: 20,
                        },
                    },
                    {
                        name: 'glyph',
                        type: 'pictorialBar',
                        barGap: '-100%',
                        symbolPosition: 'end',
                        symbolSize: 50,
                        symbolOffset: [0, '-120%'],
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        // 开工状态
        salesanalysisGclx() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/gcyl/sfzdgc?startDate=${this.selectTimeData[0]}&endDate=${this.selectTimeData[1]}`)
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.echartsName.push(item.name);
                        this.echartsGcsl.push(item.gcsl);
                        this.echartsYjfl.push(item.yjfl);
                        this.echartsYyfl.push(item.yyfl);
                    });
                    this.commenceStatusIdOneFun();
                    this.commenceStatusIdTwoFun();
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};

</script>
<style lang='stylus'>
.commence-status-child-table
    padding 0 .2rem .2rem .2rem
    .by-begion-table
        width 100%
        .el-table
            thead tr th
                background #EDF0F5
                color #022782
                font-weight 800
            td, th
                width 1rem
                padding 0
                text-align center
                font-size .14rem
        .el-table__footer tr td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
    .by-begion-echarts
        display flex
        justify-content space-between
        margin-top .2rem
        .by-begion-echarts-two
            border 1px solid #D7D7D7
            flex 1
            .echarts-box-right
                display flex
                justify-content space-between
                span
                    color #979EA7
                    font-size .14rem
                    padding .2rem
            &:first-child
                margin-right .2rem
            h1
                color #333333
                font-size .18rem
                font-weight 800
                padding .2rem
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
</style>